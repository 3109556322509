import tw from 'twin.macro'
import { useState, useEffect } from 'react'
import parse from 'html-react-parser'
import { GatsbyImage } from 'gatsby-plugin-image'

import FlexSection from '@components/FlexSection'
import Title from '@components/Title'

import { findColors, getYoutubeEmbed, componentLoaded } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_SimpleTextWithImage,
  WpPrograma_Acfpagefields_Content_SimpleTextWithImage
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_SimpleTextWithImage
  | WpPrograma_Acfpagefields_Content_SimpleTextWithImage

const SimpleTextWithImage = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const {
    image,
    text,
    title,
    bgColor,
    invertColumns,
    videoOrImage,
    video,
    tagTitle
  } = data

  const colors = findColors(bgColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <FlexSection
      title={!invertColumns ? title : true}
      tagTitle={tagTitle}
      colors={colors}
      alignTitle="left"
      index={index}
      id={title || ''}
      paddingTop
      paddingBottom
      tabs={tabs}
    >
      <div
        tw="flex flex-wrap justify-between"
        css={[invertColumns && tw`flex-row-reverse`]}
      >
        <div tw="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl w-full overflow-auto h-full mb-4 lg:mb-0">
          {invertColumns && title && (
            <Title
              tag="h1"
              title={title}
              color={colors ? colors.text : 'dark'}
            />
          )}
          <div
            tw="lg:ml-4 prose"
            css={[colors.text === 'light' && tw`text-white!`]}
          >
            {text && parse(text)}
          </div>
        </div>
        <div tw="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl w-full overflow-auto h-full mb-4 lg:mb-0">
          {image && videoOrImage === 'image' && (
            <GatsbyImage
              image={image.localFile.childImageSharp['mid']}
              alt={image.altText}
              tw="rounded-tl-[120px] rounded-br-[120px]"
            />
          )}

          {videoOrImage === 'video' && video && (
            <div tw="relative w-full">
              <div className="responsive-video">
                <iframe
                  width="100%"
                  height="100%"
                  src={getYoutubeEmbed(video)}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )}
        </div>
      </div>
    </FlexSection>
  )
}

export default SimpleTextWithImage
